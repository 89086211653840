<template>
  <v-list-item @click="$emit('click')" :key="leadForm.id" two-line>
    <v-list-item-icon>
      <v-icon v-if="leadForm.attachment"> mdi-file </v-icon>
      <v-icon v-else color="grey ligthen-3"> mdi-file-cancel </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ leadForm.attachment_name || leadForm.label }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ leadForm.created_at }} • {{ leadForm.created_at_diff }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <div>
        <v-btn
          @click.stop="$emit('click:approve', leadForm)"
          dark
          :color="thumb_up_color"
          icon
        >
          <v-icon>mdi-thumb-up</v-icon>
        </v-btn>
        <v-btn
          @click.stop="$emit('click:refuse', leadForm)"
          dark
          :color="thumb_down_color"
          icon
        >
          <v-icon>mdi-thumb-down</v-icon>
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  components: {},

  props: {
    leadForm: Object,
  },

  data() {
    return {
      showFileDialog: false,
    };
  },

  computed: {
    thumb_up_color() {
      return this.leadForm.status === "approved" ? "primary" : "grey";
    },
    thumb_down_color() {
      return this.leadForm.status === "refused" ? "red" : "grey";
    },
  },

  methods: {
    showFile() {
      this.showFileDialog = true;
    },
  },
};
</script>
