<template>
  <v-card>
    <v-card-title>
      Formulários
      <v-btn @click="loadLeadForms()" icon>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>

    <v-list>
      <v-list-item
        v-if="!leadForms.length"
        class="grey--text text-center my-10"
      >
        <v-list-item-content>
          <v-list-item-title> Nenhum formulário encontrado </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-for="leadForm in leadForms">
        <lead-form-item
          :ref="`item_${leadForm.id}`"
          :lead-form="leadForm"
          :key="leadForm.id"
          @click="handleClickItem(leadForm)"
          @click:approve="handleClickApprove(leadForm)"
          @click:refuse="handleClickRefuse(leadForm)"
        ></lead-form-item>
        <v-divider :key="`divider_${leadForm.id}`"></v-divider>
      </template>
    </v-list>

    <FileDialog
      v-model="fileDialog"
      :attachment="attachment"
      no-delete
      no-edit
    ></FileDialog>
  </v-card>
</template>

<script>
import { indexByLead, accept, reprove, reset } from "@/services/lead_forms";
import LeadFormItem from "./LeadFormItem.vue";
import FileDialog from "../../components/FileDialog.vue";

export default {
  components: { LeadFormItem, FileDialog },

  data() {
    return {
      leadForms: [],
      fileDialog: false,
      attachment: null,
    };
  },

  methods: {
    handleClickItem(leadForm) {
      if (!leadForm.attachment) {
        alert("Visualização indisponível");
        return;
      }

      this.attachment = leadForm.attachment;
      this.fileDialog = true;
    },

    async loadLeadForms() {
      const response = await indexByLead(this.$route.params.lead_number);
      this.leadForms = response.data;
    },

    updateItem(leadForm) {
      this.leadForms = this.leadForms.map((item) => {
        if (item.id === leadForm.id) {
          return { ...leadForm };
        }

        return item;
      });
    },

    handleClickApprove(leadForm) {
      if (leadForm.status === "approved") {
        return;
      }

      if (
        !confirm(
          "Ao aceitar todas as informações serão integradas ao processo, a ação será irreversível, confirmar?"
        )
      ) {
        return;
      }

      this.approveItem(leadForm);
    },

    handleClickRefuse(leadForm) {
      if (leadForm.status === "approved") {
        return;
      }

      if (leadForm.status === "refused") {
        this.resetItem(leadForm);
        return;
      }

      this.refuseItem(leadForm);
    },

    async approveItem(leadForm) {
      this.updateItem({
        ...leadForm,
        status: "approved",
      });

      try {
        await accept(leadForm.id);
        this.$store.commit("alert", "Informações atualizadas");
        this.$store.dispatch("getLead", this.$route.params.lead_number);
      } catch (error) {
        console.error(error);
        this.updateItem({
          ...leadForm,
          status: null,
        });
        this.$store.commit(
          "error",
          "O relatório é incompatível, por favor gerar uma nova versão"
        );
      }
    },

    async refuseItem(leadForm) {
      this.updateItem({
        ...leadForm,
        status: "refused",
      });

      try {
        await reprove(leadForm.id);
      } catch (error) {
        console.error(error);
        this.updateItem({
          ...leadForm,
          status: null,
        });
      }
    },

    async resetItem(leadForm) {
      const oldStatus = leadForm.status;

      this.updateItem({
        ...leadForm,
        status: null,
      });

      try {
        await reset(leadForm.id);
      } catch (error) {
        console.error(error);
        this.updateItem({
          ...leadForm,
          status: oldStatus,
        });
      }
    },
  },

  created() {
    this.loadLeadForms();
  },
};
</script>
