import Vue from "vue";

export async function indexByLead(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/lead_forms/leads/${lead_number}`
  );

  return response.data;
}

export async function accept(id) {
  const response = await Vue.prototype.$http.post(`/lead_forms/${id}/accept`);

  return response.data;
}

export async function reprove(id) {
  const response = await Vue.prototype.$http.post(`/lead_forms/${id}/reprove`);

  return response.data;
}

export async function reset(id) {
  const response = await Vue.prototype.$http.post(`/lead_forms/${id}/reset`);

  return response.data;
}
